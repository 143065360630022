import {Component, OnInit} from '@angular/core';
import {
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {FormatService} from '../../../../services/format.service';
import {YoungWebservice} from '../../../../services/webservices/young.webservice';
import {ParamWebservice} from '../../../../services/webservices/param.webservice';
import {PParcours} from '../../../../model/param/p-parcours.model';
import {Destroyed} from '../../directives/destroyed.directive';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatOptionModule} from '@angular/material/core';
import {NgFor, NgIf} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';

@Component({
  selector: 'app-dialog-select-parcours',
  templateUrl: './dialog-select-parcours.component.html',
  styleUrls: ['./dialog-select-parcours.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    NgFor,
    MatOptionModule,
    NgIf,
    MatProgressSpinnerModule,
    MatDialogActions
  ]
})
export class DialogSelectParcoursComponent extends Destroyed implements OnInit {
  isLoading = true;

  chosenParcours: PParcours[];

  public lParcours: PParcours[];

  constructor(
    private readonly dialogRef: MatDialogRef<DialogSelectParcoursComponent>,
    private readonly formatService: FormatService,
    private readonly youngService: YoungWebservice,
    private readonly paramWebservice: ParamWebservice
  ) {
    super();
  }

  ngOnInit() {
    this.chosenParcours = [];
    this.initParcours();
  }

  onDismiss(): void {
    this.dialogRef.close({dismiss: true, data: null});
  }

  close() {
    this.dialogRef.close();
  }

  add() {
    this.dialogRef.close(this.chosenParcours);
  }

  private initParcours() {
    this.paramWebservice
      .getAllParcours()
      .pipe(this.untilDestroyed())
      .subscribe({
        next: (value: PParcours[]) => {
          this.lParcours = value;
        },
        complete: () => {
          this.isLoading = false;
        }
      });
  }
}
