<h1 class='m-0' mat-dialog-title>Ajouter un parcours</h1>
<div class='d-flex flex-row align-items-center my-2 mx-2' mat-dialog-content>
  <mat-form-field appearance='fill'>
    <mat-label>Parcours</mat-label>
    <mat-select [(ngModel)]='chosenParcours' multiple>
      <mat-option *ngFor='let parcours of lParcours' [value]='parcours'>
        {{parcours.label}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button (click)='add()' class='btn btn-primary my-2 mx-2' type='button'>Ajouter</button>
  <div *ngIf='isLoading'>
    <div class='d-flex flex-row justify-content-center align-items-center py-5'>
      <mat-spinner [diameter]='50' class='custom-theme' color='primary'></mat-spinner>
    </div>
  </div>
</div>

<div class='d-flex flex-row justify-content-evenly' mat-dialog-actions>
  <button (click)='close()' class='btn btn-danger my-2 mx-2' type='button'>Annuler</button>
</div>

