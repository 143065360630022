<div class='dialog-default d-flex flex-column'>
  <h1 class='m-0' mat-dialog-title>Ajouter un pilier</h1>
  <div class='d-flex flex-row align-items-center my-2 mx-2'>
    <mat-form-field appearance='outline' disabled='isLoading'>
      <mat-label>Pilier</mat-label>
      <mat-select [(ngModel)]='chosenPillar' multiple>
        <mat-option *ngFor='let pillar of lPillars' [value]='pillar'>
          {{pillar.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button (click)='add()' class='btn btn-primary my-2 mx-2' type='button'>Ajouter</button>
    <div *ngIf='isLoading'>
      <div class='d-flex flex-row justify-content-center align-items-center py-5'>
        <mat-spinner [diameter]='50' class='custom-theme' color='primary'></mat-spinner>
      </div>
    </div>
  </div>

  <div class='d-flex flex-row justify-content-center'>
    <button (click)='close()' class='btn btn-danger my-2 mx-2' type='button'>Annuler</button>
  </div>

</div>

