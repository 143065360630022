<div class="dialog-default d-flex flex-column p-4">
  <div class="d-flex flex-row mb-3">
    <span class="m-0 dialog-title">
      Ce projet/ensemble contient {{events.length > 1 ? 'plusieurs événements' : 'un événement'}}.

    </span>
    <span class="flex-fill separator"></span>
    <button (click)="abortAction()"
            aria-label="Close"
            class="close"
            type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <p class="my-0">Si vous voulez récupérer les jeunes et les responsables d'insertion,</p>
  <p class="my-0">Choisissez l'événement correspondant :</p>
  <div class="radio-group my-3 flex-fill" style="overflow-y: scroll;height: 65vh;">
    <div *ngFor="let _event of events" class="custom-control custom-radio">
      <input
        [(ngModel)]="result"
        [value]="_event.id"
        class="custom-control-input"
        id="{{'radio' + _event.id}}"
        type="radio">
      <label class="custom-control-label" for="{{'radio' + _event.id}}">{{formatEventName(_event)}}<span
        class='badge ml-2 date-badge'>{{_event.dateBegin | date : 'dd/MM/yyyy'}}
        - {{_event.dateEnd | date : 'dd/MM/yyyy'}}</span> </label>
    </div>
  </div>
  <div class="my-md-3 my-5 row align-items-center justify-content-end">
    <button (click)="abortAction()" class="btn btn-outline-primary mr-5">Passer</button>
    <button (click)="exit()" class="btn btn-primary mr-5">Valider</button>
  </div>
</div>





