import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle} from '@angular/material/dialog';
import {Event} from '../../../../model/event/event.model';
import {FormsModule} from '@angular/forms';
import {DatePipe, NgFor} from '@angular/common';

@Component({
  selector: 'app-dialog-select-young-event',
  templateUrl: './dialog-select-young-event.component.html',
  styleUrls: ['./dialog-select-young-event.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, NgFor, FormsModule, DatePipe]
})
export class DialogSelectYoungEventComponent implements OnInit, OnDestroy {
  result;
  abort = true;

  events: Event[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogSelectYoungEventComponent>,
    @Inject(MAT_DIALOG_DATA) public data: unknown
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    if (!this.events || this.events.length === 0) {
      this.dialogRef.close();
    }
  }

  exit() {
    this.abort = false;
    this.dialogRef.close();
  }

  abortAction() {
    this.abort = true;
    this.dialogRef.close();
  }

  ngOnDestroy() {
    // Exit without choose young or create new
    if (this.abort) {
      this.dialogRef.close();
    } else {
      this.dialogRef.close(this.result ? this.result : {});
    }
  }

  formatEventName(event: Event) {
    return 'Événement : ' + event.eventType + ' - Capacité : ' + event.numberPlaces;
  }
}
