import {Component, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {MatDialogRef, MatDialogTitle} from '@angular/material/dialog';
import {FormatService} from '../../../../services/format.service';
import {YoungWebservice} from '../../../../services/webservices/young.webservice';
import {ParamWebservice} from '../../../../services/webservices/param.webservice';
import {PPillar} from '../../../../model/param/p-pillar.model';
import {Destroyed} from '../../directives/destroyed.directive';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatOptionModule} from '@angular/material/core';
import {NgFor, NgIf} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';

@Component({
  selector: 'app-dialog-select-pillar',
  templateUrl: './dialog-select-pillar.component.html',
  styleUrls: ['./dialog-select-pillar.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    NgFor,
    MatOptionModule,
    NgIf,
    MatProgressSpinnerModule
  ]
})
export class DialogSelectPillarComponent extends Destroyed implements OnInit {
  isLoading = true;

  chosenPillar: PPillar[];

  public lPillars: PPillar[];

  constructor(
    private readonly dialogRef: MatDialogRef<DialogSelectPillarComponent>,
    private readonly formatService: FormatService,
    private readonly youngService: YoungWebservice,
    private readonly paramWebservice: ParamWebservice
  ) {
    super();
  }

  ngOnInit() {
    this.chosenPillar = [];
    this.initPillars();
  }

  onDismiss(): void {
    this.dialogRef.close({dismiss: true, data: null});
  }

  close() {
    this.dialogRef.close();
  }

  add() {
    this.dialogRef.close(this.chosenPillar);
  }

  private initPillars() {
    this.paramWebservice
      .getAllPillars()
      .pipe(this.untilDestroyed())
      .subscribe(
        (value: PPillar[]) => {
          this.lPillars = value;
        },
        (err) => {},
        () => {
          this.isLoading = false;
        }
      );
  }
}
